<template>
  <div v-if="delivery" class="mt-2.5 md:mt-1.5 flex flex-col md:flex-row gap-5 text-primaryText">
    <div class="flex items-center">
      <img v-if="icon" width="20" height="20" :src="`/icon/${delivery.icon}`" :alt="delivery.alt" aria-hidden="true" class="h-5">
      <span :class="`${icon ? 'ml-2' : ''} text-sm leading-4`">
        {{ delivery.display }}
      </span>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  service: {
    type: Object,
    required: true
  },
  icon: {
    type: Boolean,
    default: true
  }
})

const available = computed(() => props.service && props.service.delivery ? availabilities[props.service.delivery[0]] : null)
const deliveryArea = computed(() => props.service ? props.service.delivery_areas.join(', ') : '')
const delivery = computed(() => {
  if (!props.service || !available.value) return null

  if (props.service.physical_location) return {
    ...available.value,
    display: `${available.value.title}: ${props.service.address}`
  }

  return {
    ...available.value,
    display: `${available.value.title}: ${deliveryArea.value}`,
  }
})
</script>